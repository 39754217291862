import { isBrowser } from './environment';
import Storage from './storage';
import {
  STORAGE_CART,
  STORAGE_CLIENT,
} from './constants';
import { BAD_REQUEST, UNAUTHORIZED, NOT_FOUND } from '../const/httpCodes';
import ApiException from '../exceptions/ApiException';
import RollbarLogger from './RollbarLogger';

export default class Api {
  constructor(props) {
    if (!isBrowser()) {
      this.domain = props.domain;
    }
  }

    getHeader = async () => {
      const response = await this.get('/api/v2/header');
      return response.data;
    }

    /**
     * @return {Promise<import('../types').ApiProductList>}
     */
    getProducts = async () => {
      const response = await this.get('/api/v2/product/list');
      return response.data;
    }

    getProduct = async (posId) => {
      const response = await this.get(`/api/v2/product/${posId}`);
      return response.data;
    }

    getPage = async (slug) => {
      const response = await this.get(`/api/v2/page/${slug}`);
      return response.data;
    }

    /**
     * @param {string} tableHash
     * @returns {Promise<import('../types').ApiHashSettings>}
     */
    getQrHubSettings = async (tableHash) => {
      const response = await this.get(`/api/v2/${tableHash}/settings`);
      return response.data;
    }

    getSitemapData = async () => {
      const response = await this.get('/api/v2/sitemap/show');
      return response.data;
    }

    getPaymentData = async (uuid) => {
      const response = await this.get(`/api/v2/payment/params?uuid=${uuid}`);
      return response.data;
    }

    addClient = async ({ name, phone, shopCountry }) => {
      const response = await this.modernPost('/api/v2/client', { name, phone, shopCountry });

      return response;
    }

    createOrder = async ({
      clientId, cart, spotId, address, address2, addressComment, comment, deliveryType, paymentType,
      deliveryTime, lat, lng,
    }) => {
      let response = false;
      try {
        response = await this.modernPost('/api/v2/order', {
          client_id: clientId,
          cart,
          spot_id: spotId,
          address,
          address2,
          address_comment: addressComment,
          comment,
          delivery_type: deliveryType,
          payment_type: paymentType,
          delivery_time: deliveryTime,
          lat,
          lng,
        });
      } catch (error) {
        RollbarLogger.error(
          'Front create order error',
          error,
          {
            clientId,
            cart,
            spotId,
            address,
            address2,
            addressComment,
            comment,
            deliveryType,
            paymentType,
            deliveryTime,
            lat,
            lng,
          },
        );

        throw new Error('Create order error');
      }

      RollbarLogger.log('Front Create order', {
        clientId,
        cart,
        spotId,
        address,
        address2,
        addressComment,
        comment,
        deliveryType,
        paymentType,
        deliveryTime,
        cartInStorage: Storage.get(STORAGE_CART) || {},
        clientInStorage: Storage.get(STORAGE_CLIENT) || {},
      });

      return response;
    }

    /**
     * @param {string} tableHash Хеш стола
     * @returns {Promise<{ settings?: Object<*>, transactions?: Array<*>, anonymous?: boolean, exists?: boolean}>}
     */
    // eslint-disable-next-line consistent-return
    getTableData = async (tableHash) => {
      try {
        const response = await this.get(`/api/v2/${tableHash}/table`);
        return response.data;
      } catch (error) {
        if (error instanceof ApiException) {
          if (error.statusCode === UNAUTHORIZED) {
            return {
              exists: true,
              anonymous: true,
            };
          }
          if (error.statusCode === NOT_FOUND) {
            return {
              exists: false,
              anonymous: false,
            };
          }
        } else {
          throw error;
        }
      }
    }

    /**
     * @param {Object}
     * @returns {Promise<import('../types').ApiHashPaymentData>}
     */
    getQrPaymentData = async ({
      tableHash,
      orderId,
      amount,
      tips,
      userId,
      serviceCharge,
      productIds,
    }) => {
      const {
        paymentId, paymentData, paymentStatus, provider, canTip,
      } = await this.post(`/api/v2/${tableHash}/order/payment`, {
        tableHash,
        orderId,
        amount,
        tips,
        userId,
        serviceCharge,
        productIds,
      });
      return {
        provider, paymentId, paymentData, paymentStatus, canTip,
      };
    }

    getQrPaymentStatus = async (tableHash, paymentId) => {
      const { payment } = await this.get(`/api/v2/${tableHash}/order/payment/${paymentId}`);

      return payment?.status;
    }

    createReview = async (tableHash, data) => {
      const response = await this.post(`/api/v2/${tableHash}/reviews/create`, data);

      return response;
    };

    prepareRequest = (url) => {
      const { domain } = this;
      let headers;
      let finalUrl;

      if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'review') {
        finalUrl = `https://${process.env.NEXT_PUBLIC_SHOP_API_URL}${url}`;
        headers = { DOMAIN: process.env.NEXT_PUBLIC_REVIEW_DOMAIN };
      } else if (isBrowser()) {
        finalUrl = `${window.location.origin}${url}`;
        headers = { DOMAIN: window.location.hostname }; // TODO: убрать на стороне PHP авторизацию по заголовку если запрос с фронта
      } else {
        finalUrl = `${process.env.NEXT_PUBLIC_SHOP_API_URL}${url}`;
        headers = { DOMAIN: domain, HOST: domain };
      }

      return [headers, finalUrl];
    }

    /**
     * @param {string} url
     * @return {Promise<Object<*>>}
     */
    get = async (url) => {
      const [headers, finalUrl] = this.prepareRequest(url);

      let response;
      let json;

      try {
        response = await fetch(finalUrl, { headers });
        json = await response.json();
      } catch (error) {
        throw new ApiException(
          'Error occurred while fetching data from SHOP API',
          response?.status,
          {
            method: 'GET',
            headers,
            url: finalUrl,
            error,
          },
        );
      }

      if (response.status >= BAD_REQUEST) {
        throw new ApiException(
          json?.message,
          response?.status,
          {
            method: 'GET',
            headers,
            url: finalUrl,
            json,
          },
        );
      }

      return json;
    }

    /**
     * Для роботи з новими методами API
     */
    modernGet = async (url) => {
      const [headers, finalUrl] = this.prepareRequest(url);

      const res = await fetch(finalUrl, { headers });
      return {
        ok: res.ok,
        status: res.status,
        json: await res.json(),
      };
    }

    /**
     * Для роботи з новими методами API
     */
    modernPost = async (url, body) => {
      const [headers, finalUrl] = this.prepareRequest(url);
      Object.assign(headers || {}, { 'Content-Type': 'application/json' });

      const res = await fetch(finalUrl, {
        headers,
        body: JSON.stringify(body),
        method: 'POST',
      });

      return {
        ok: res.ok,
        status: res.status,
        json: await res.json(),
      };
    }

    post = async (url, body) => {
      const [headers, finalUrl] = this.prepareRequest(url);
      Object.assign(headers || {}, { 'Content-Type': 'application/json' });

      if (finalUrl.indexOf('/api/v1/order/create') !== -1) {
        return this.CreateOrderPost(headers, finalUrl, body);
      }

      const res = await fetch(finalUrl, {
        headers,
        body: JSON.stringify(body),
        method: 'POST',
      });

      return res.json();
    }

    // @todo удалить когда разберемся почему не приходят заказы
    CreateOrderPost = async (headers, finalUrl, body) => {
      let res = { success: false };
      Object.assign(headers || {}, { 'Content-Type': 'application/json' });

      try {
        res = await fetch(finalUrl, {
          headers,
          body: JSON.stringify(body),
          method: 'POST',
        });

        return res.json();
      } catch (e) {
        RollbarLogger.error('Create order exception', {
          e,
          headers,
          body,
        });
      }

      return res;
    }

    /**
     * @param {Array} items
     * @returns {Promise<import('../types').ApiSiteInfo>}
     */
    getSiteInfo = async (items) => {
      const itemsParamValue = Array.isArray(items) ? items.join(',') : '';
      const response = await this.get(`/api/v2/site/info?items=${itemsParamValue}`);
      return response.data;
    }

    getQrMenuStatus = async () => {
      const { data } = await this.get('/api/v2/qr-menu/status');
      return data;
    }
}
